import axios from 'axios';
import URLParser from '../URLParser';

class ApplePayService {
  static canMakePayments() {
    return new Promise((resolve, reject) => {
      if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
        resolve({
          result: true,
        });
      } else {
        reject(new Error('Browser does not support Apple Pay'));
      }
    });
  }

  static getDefaultShippingMethod(request) {
    return request.shippingMethods[0];
  }

  static async begin(request) {
    return new Promise(resolve => {
      let selectedShippingMethod;

      const session = new ApplePaySession(6, {
        countryCode: request.country,
        currencyCode: request.currency,
        merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
        shippingMethods: request.shippingMethods,
        shippingType: request.shippingType,
        supportedNetworks: request.cardBrands,
        requiredBillingContactFields: request.requiredBillingContactFields,
        requiredShippingContactFields: request.requiredShippingContactFields.concat(
          request.contactFields,
        ),
        lineItems: request.lineItems,
        total: {
          label: request.totalLabel,
          amount: request.price,
          type: request.totalType,
        },
      });

      session.onshippingcontactselected = function onshippingcontactselected() {
        session.completeShippingContactSelection({
          newTotal: {
            type: request.totalType,
            amount: request.price,
            label: request.totalLabel,
          },
          newLineItems: request.lineItems,
        });
      };

      session.onshippingmethodselected = function onshippingmethodselected(event) {
        selectedShippingMethod = event.shippingMethod;

        session.completeShippingMethodSelection({
          newTotal: {
            type: request.totalType,
            amount: parseFloat(request.price) + parseFloat(event.shippingMethod.amount),
            label: request.totalLabel,
          },
          newLineItems: request.lineItems,
        });
      };

      session.onpaymentauthorized = function onpaymentauthorized(event) {
        session.completePayment({
          status: 'STATUS_SUCCESS',
        });

        resolve({
          payment: event.payment,
          shippingMethod: selectedShippingMethod,
        });
      };

      session.onpaymentmethodselected = function onpaymentmethodselected() {
        session.completePaymentMethodSelection({
          newTotal: {
            type: request.totalType,
            amount: request.price,
            label: request.totalLabel,
          },
          newLineItems: request.lineItems,
        });
      };

      session.oncancel = function oncancel(event) {
        throw new Error(event);
      };

      session.onvalidatemerchant = function onvalidatemerchant(event) {
        selectedShippingMethod = ApplePayService.getDefaultShippingMethod(request);
        axios
          .get(
            `${URLParser.gwroot}/token/apple_pay_create_session.php?` +
              `validationUrl=${event.validationURL}&` +
              `tokenizationKey=${request.tokenizationKey}& ` +
              `domainName=${request.domainName}`,
          )
          .then(response => response.data)
          .then(json => {
            session.completeMerchantValidation(json);
          });
      };

      session.begin();
    });
  }
}

export default ApplePayService;
